var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "join-row-wrap"
  }, [_vm._l(_vm.termsList, function (terms) {
    return _c('div', {
      staticClass: "join-row"
    }, [_c('div', {
      staticClass: "join-caption"
    }, [_c('h3', {
      staticClass: "title"
    }, [_vm._v(_vm._s(terms.subject))]), _c('label', {
      staticClass: "check"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selected,
        expression: "selected"
      }],
      staticClass: "input",
      attrs: {
        "name": "terms",
        "type": "checkbox"
      },
      domProps: {
        "value": terms._id,
        "checked": Array.isArray(_vm.selected) ? _vm._i(_vm.selected, terms._id) > -1 : _vm.selected
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.selected,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;
          if (Array.isArray($$a)) {
            var $$v = terms._id,
              $$i = _vm._i($$a, $$v);
            if ($$el.checked) {
              $$i < 0 && (_vm.selected = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selected = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selected = $$c;
          }
        }, _vm.input]
      }
    }), _c('i', {
      staticClass: "icon"
    }), _c('span', {
      staticClass: "text"
    }, [_vm._v("동의")])])]), terms.content ? _c('div', {
      staticClass: "join-box"
    }, [_c('textarea', {
      staticClass: "textarea",
      attrs: {
        "name": "terms",
        "readonly": ""
      }
    }, [_vm._v(_vm._s(terms.content) + " ")])]) : _vm._e()]);
  }), _c('div', {
    staticClass: "join-row"
  }, [_c('div', {
    staticClass: "join-caption join-caption--block"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("전체선택")]), _c('label', {
    staticClass: "check"
  }, [_c('input', {
    staticClass: "input",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.termsList.length == _vm.selected.length
    },
    on: {
      "click": function ($event) {
        _vm.selected = _vm.termsList.length == _vm.selected.length ? [] : _vm.termsList.map(function (terms) {
          return terms._id;
        });
        _vm.input();
      }
    }
  }), _c('i', {
    staticClass: "icon"
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("동의")])])])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }