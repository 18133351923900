<template>
    <div class="join-row-wrap">
        <div v-for="terms in termsList" class="join-row">
            <div class="join-caption">
                <h3 class="title">{{ terms.subject }}</h3>
                <label class="check">
                    <input v-model="selected" name="terms" class="input" type="checkbox" :value="terms._id" @change="input">
                    <i class="icon"></i>
                    <span class="text">동의</span>
                </label>
            </div>
            <div v-if="terms.content" class="join-box">
                <textarea class="textarea" name="terms" readonly>{{ terms.content }}
                </textarea>
            </div>
        </div>
        <!-- 전체동의 -->
        <div class="join-row">
            <div class="join-caption join-caption--block">
                <h3 class="title">전체선택</h3>
                <label class="check"><input type="checkbox" class="input" :checked="termsList.length == selected.length" @click="selected = (termsList.length == selected.length) ? [] : termsList.map((terms) => terms._id); input()">
                    <i class="icon"></i>
                    <span class="text">동의</span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: { type: Array, default: [] },
        codes: { type: Array, default: [] },
        code: { type: String, default: null }
    },
    data: () => {
        return {
            termsList: [],
            selected: [],
        }
    },
    created(){
        this.init();
    },
    methods: {
        input(){
            this.$emit('input', this.selected);
        },
        async init(){
            try{
                this.selected = this.value;
                await this.getTerms();
            }
            catch(error){
                console.error(error);
            }
        },
        check(){
            try{
                this.termsList.forEach((terms) => {
                    if(terms.necessary && this.selected.indexOf(terms._id) < 0){
                        throw new Error(`[${terms.subject}]에 동의해 주세요`);
                    }
                })

                return true;
            }
            catch(error){
                alert(error.message);
                return false;
            }
        },
        getTerms(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/v1/terms`, { params: [this.code].concat(this.codes) });

                    this.termsList = res.data.termsList;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    watch: {
        value(){
            this.selected = this.value;
        }
    }
}
</script>
