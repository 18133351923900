<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
				<h2 class="join-title">회원가입</h2>

				<div class="join-container">
					<!-- 가입 단계 표시 -->
					<nav class="join-step">
						<ul class="join-step-lists">
							<li class="list">
								<span class="badge">1</span>
								<span class="text">약관동의</span>
							</li>
							<li class="list">
								<span class="badge">2</span>
								<span class="text">정보입력</span>
							</li>
							<li class="list list--on">
								<span class="badge">3</span>
								<span class="text">가입완료</span>
							</li>
						</ul>
					</nav>
					<div class="join-complate">
						<i class="join-complate__icon"></i>
						<h3 class="join-complate__title">회원가입이　완료 되었습니다.</h3>
						<p class="join-complate__text">가입해 주셔서 감사합니다.</p>
					</div>
					<!-- 하단버튼 -->
					<div class="bottom-button">
						<a v-if="$route.query.code === 'sample'" class="button button--primary" href="/community/sample"><span>확인</span></a>
						<a v-else class="button button--primary" href="/"><span>확인</span></a>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	created() {
	},
	mounted() {
		this.init();
	},
	methods : {
		init : function() {
		}
	},
}
</script>
