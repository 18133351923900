var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_vm._m(0), _c('terms-component', {
    ref: "terms",
    attrs: {
      "codes": ['tos', 'privacy', 'marketing']
    },
    model: {
      value: _vm.$data._terms,
      callback: function ($$v) {
        _vm.$set(_vm.$data, "_terms", $$v);
      },
      expression: "$data._terms"
    }
  }), _c('div', {
    staticClass: "bottom-button"
  }, [_c('a', {
    staticClass: "button button--border-primary",
    attrs: {
      "href": "",
      "onclick": "return false;"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', [_vm._v("취소하기")])]), _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": _vm.submit
    }
  }, [_c('span', [_vm._v("계속 진행")])])])], 1)])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "join-step"
  }, [_c('ul', {
    staticClass: "join-step-lists"
  }, [_c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("1")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("약관동의")])]), _c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("2")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("정보입력")])]), _c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("3")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("가입완료")])])])]);

}]

export { render, staticRenderFns }