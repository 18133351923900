<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
                <h2 class="join-title">회원가입</h2>

				<div class="join-container">
					<!-- 가입 단계 표시 -->
					<nav class="join-step">
						<ul class="join-step-lists">
							<li class="list">
								<span class="badge">1</span>
								<span class="text">약관동의</span>
							</li>
							<li class="list list--on">
								<span class="badge">2</span>
								<span class="text">정보입력</span>
							</li>
							<li class="list">
								<span class="badge">3</span>
								<span class="text">가입완료</span>
							</li>
						</ul>
					</nav>
					<!-- 약관동의 -->
					<div class="join-row-wrap">
						<div class="join-row join-row--form">
							<div class="join-caption join-caption--center">
								<h3 class="title">회원정보 필수입력</h3>
							</div>
							<div role="table" class="join-table">
								<div role="rowgroup">
									<div role="row">
										<div role="columnheader">아이디(이메일)</div>
										<div role="cell">
											<!-- 이메일 폼 -->
											<div class="form-email">
												<input v-model="email1" class="input" type="text" @input="email1 = email1.replace(/[^0-9a-zA-Z.\-]/,'')">
												<span class="text">@</span>
												<input v-model="email2" class="input" type="text" @input="email2 = email2.replace(/[^0-9a-zA-Z.\-]/,'')">

												<select class="select" @change="email2 = $event.target.value">
													<option value="">직접입력</option>
													<option value="naver.com">naver.com</option>
													<option value="daum.net">daum.net</option>
													<option value="gmail.com">gmail.com</option>
												</select>
											</div>
										</div>
									</div>
									<div role="row">
										<div role="columnheader">이름</div>
										<div role="cell">
											<input v-model="user.name" type="text" class="input">
										</div>
									</div>
									<div role="row">
										<div role="columnheader">휴대전화</div>
										<div role="cell">
											<!-- 연락처 폼 -->
											<div class="form-tel">
												<select v-model="phone1" class="select" name="">
													<option value="010">010</option>
													<option value="011">011</option>
													<option value="016">016</option>
													<option value="017">017</option>
													<option value="019">019</option>
												</select>
												<span class="text"></span>
												<input v-model="phone2" class="input" type="tel" @input="phone2 = phone2.replace(/[^0-9]/,'')" maxlength="4">
												<span class="text"></span>
												<input v-model="phone3" class="input" type="tel" @input="phone3 = phone3.replace(/[^0-9]/,'')" maxlength="4">
											</div>
										</div>
									</div>
									<div v-show="$route.query.accessToken === undefined" role="row">
										<div role="columnheader">비밀번호</div>
										<div role="cell">
											<input v-model="password" class="input" type="password" name="" value="">
											<small class="text-grey text-grey--right">(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)</small>
										</div>
									</div>
									<div v-show="$route.query.accessToken === undefined" role="row">
										<div role="columnheader">비밀번호 확인</div>
										<div role="cell">
											<input v-model="password2" class="input" type="password" name="" value="">
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- 하단버튼 -->
					<div class="bottom-button">
						<a class="button button--border-primary" href="" onclick="return false;" @click="$router.go(-1)"><span>취소하기</span></a>
						<a class="button button--primary" @click="join"><span>회원가입</span></a>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
const CryptoAES = require('../../../plugins/crypto-aes');

import MainHeader from "../../../components/client/inc/main-header.vue";
import MainFooter from "../../../components/client/inc/main-footer.vue";

export default{
	components: {
		MainHeader,
		MainFooter
	},
	data: function(){
		return{
			user: {
				_terms: [],
				pets: [],
			},

			petsDefaultItem: {
				type: "puppy",
				name: null,
				sex: "female",
				birthYear: null,
				birthMonth: null,
				birthDay: null,
				weight: 0,
				neutralization: true,
				memo: null
			},

			password: null,
			password2: null,

			phone1: '010',
			phone2: null,
			phone3: null,

			email1: null,
			email2: null,
		}
	},
	mounted() {
		this.init();
	},
	methods : {
		init() {
			this.user.pets.push(Object.assign({}, this.petsDefaultItem ));
			this.user._terms = JSON.parse(this.$cookies.get("_terms"));
		},

		async join(){
			try{
				if(!this.email1 || !this.email2) throw new Error("아이디(이메일)을 입력해주세요");
				if(!this.user.name) throw new Error("이름을 입력해주세요");
				if(!this.phone1 || !this.phone2 || !this.phone3) throw new Error("휴대전화번호를 입력해주세요");
				if(!this.$route.query.accessToken && !this.password) throw new Error("비밀번호를 입력해주세요");
				if(!this.$route.query.accessToken && !this.password2) throw new Error("비밀번호확인을 입력해주세요");
				if(this.password != this.password2) throw new Error("비밀번호 및 비밀번호확인이 일치하지 않습니다");

				////////////////////////////////////////////////////////////////
				// 일반 계정 가입
				////////////////////////////////////////////////////////////////
				if(this.$route.query.accessToken === undefined){
					await this.postUser(
						Object.assign(this.user, {
							username: this.username,
							email: this.email,
							phone: this.phone,
							password: CryptoAES.encrypt(this.password),
						})
					);
				}
				////////////////////////////////////////////////////////////////
				// SNS 계정 회원정보 통합
				////////////////////////////////////////////////////////////////
				else{
					var user = await this.putUser(
						Object.assign(this.user, {
							username: this.username,
							email: this.email,
							phone: this.phone,
							password: CryptoAES.encrypt(this.password),
						})
					);
				}

				if(this.$route.query.code === 'sample'){
					this.$router.push({ name: "join-complete", query: { code: "sample" } });
				} else {
					this.$router.push({ name: "join-complete" });
				}

			}
			catch(error){
				console.log(error);
				if(error.response) alert(error.response.data.message);
				else alert(error.message.toString());
			}
		},

		addPet(){
			this.user.pets.push(Object.assign({}, this.petsDefaultItem));
		},

		postUser(user){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.post('/api/v1/users', user);

					resolve(res.data.user);
				}
				catch(error){
					reject(error);
				}
			});
		},

		putUser(user){
			return new Promise(async (resolve, reject) => {
				try{
					var res = await this.$http.put('/api/v1/me/info', user, {
						headers: { "Authorization": `Bearer ${this.$route.query.accessToken}` }
					});

					resolve(res.data.user);
				}
				catch(error){
					reject(error);
				}
			});
		},
	},
	computed: {
		username(){
			return (this.email1 && this.email2) ? [this.email1, this.email2].join('@') : undefined;
		},
		email(){
			return (this.email1 && this.email2) ? [this.email1, this.email2].join('@') : undefined;
		},
		phone(){
			return (this.phone1 && this.phone2 && this.phone3) ? [this.phone1, this.phone2, this.phone3].join('-') : undefined;
		}
	}
}
</script>
