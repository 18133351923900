var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "bottom-button"
  }, [_vm.$route.query.code === 'sample' ? _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "href": "/community/sample"
    }
  }, [_c('span', [_vm._v("확인")])]) : _c('a', {
    staticClass: "button button--primary",
    attrs: {
      "href": "/"
    }
  }, [_c('span', [_vm._v("확인")])])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "join-step"
  }, [_c('ul', {
    staticClass: "join-step-lists"
  }, [_c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("1")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("약관동의")])]), _c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("2")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("정보입력")])]), _c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("3")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("가입완료")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "join-complate"
  }, [_c('i', {
    staticClass: "join-complate__icon"
  }), _c('h3', {
    staticClass: "join-complate__title"
  }, [_vm._v("회원가입이　완료 되었습니다.")]), _c('p', {
    staticClass: "join-complate__text"
  }, [_vm._v("가입해 주셔서 감사합니다.")])]);

}]

export { render, staticRenderFns }