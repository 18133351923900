<template>
	<div class="wrap wrap--products">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
		<main class="main" role="main">
			<div class="container">
                <h2 class="join-title">회원가입</h2>

				<div class="join-container">
					<!-- 가입 단계 표시 -->
					<nav class="join-step">
						<ul class="join-step-lists">
							<li class="list list--on">
								<span class="badge">1</span>
								<span class="text">약관동의</span>
							</li>
							<li class="list">
								<span class="badge">2</span>
								<span class="text">정보입력</span>
							</li>
							<li class="list">
								<span class="badge">3</span>
								<span class="text">가입완료</span>
							</li>
						</ul>
					</nav>

					<!-- 약관동의 -->
					<terms-component v-model="$data._terms" ref="terms" :codes="['tos','privacy','marketing']"></terms-component>

					<!-- 하단버튼 -->
					<div class="bottom-button">
						<a class="button button--border-primary" href="" onclick="return false;" @click="$router.go(-1)"><span>취소하기</span></a>
						<a class="button button--primary" @click="submit"><span>계속 진행</span></a>
					</div>
				</div>
			</div>
		</main>

		<!-- 푸터 -->
		<main-footer></main-footer>
	</div>
</template>

<script>
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

import TermsComponent from "@/components/client/terms/terms-component.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		TermsComponent
	},
	data: function(){
		return {
			_terms: []
		}
	},
	methods : {
		submit(){
			try{
				if(this.$refs.terms.check()){
					this.$cookies.set('_terms', JSON.stringify(this.$data._terms));

					this.$router.push({
						name: "join-form",
						query: this.$route.query
					});
				}
			}
			catch(error){
				alert(error.message.toString());
			}
		},
	},
}
</script>
