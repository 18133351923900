var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--products"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "container"
  }, [_c('h2', {
    staticClass: "join-title"
  }, [_vm._v("회원가입")]), _c('div', {
    staticClass: "join-container"
  }, [_vm._m(0), _c('div', {
    staticClass: "join-row-wrap"
  }, [_c('div', {
    staticClass: "join-row join-row--form"
  }, [_vm._m(1), _c('div', {
    staticClass: "join-table",
    attrs: {
      "role": "table"
    }
  }, [_c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("아이디(이메일)")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-email"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email1,
      expression: "email1"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.email1
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.email1 = $event.target.value;
      }, function ($event) {
        _vm.email1 = _vm.email1.replace(/[^0-9a-zA-Z.\-]/, '');
      }]
    }
  }), _c('span', {
    staticClass: "text"
  }, [_vm._v("@")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email2,
      expression: "email2"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.email2
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.email2 = $event.target.value;
      }, function ($event) {
        _vm.email2 = _vm.email2.replace(/[^0-9a-zA-Z.\-]/, '');
      }]
    }
  }), _c('select', {
    staticClass: "select",
    on: {
      "change": function ($event) {
        _vm.email2 = $event.target.value;
      }
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("직접입력")]), _c('option', {
    attrs: {
      "value": "naver.com"
    }
  }, [_vm._v("naver.com")]), _c('option', {
    attrs: {
      "value": "daum.net"
    }
  }, [_vm._v("daum.net")]), _c('option', {
    attrs: {
      "value": "gmail.com"
    }
  }, [_vm._v("gmail.com")])])])])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("이름")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.user.name,
      expression: "user.name"
    }],
    staticClass: "input",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.user.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.user, "name", $event.target.value);
      }
    }
  })])]), _c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("휴대전화")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('div', {
    staticClass: "form-tel"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone1,
      expression: "phone1"
    }],
    staticClass: "select",
    attrs: {
      "name": ""
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.phone1 = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "010"
    }
  }, [_vm._v("010")]), _c('option', {
    attrs: {
      "value": "011"
    }
  }, [_vm._v("011")]), _c('option', {
    attrs: {
      "value": "016"
    }
  }, [_vm._v("016")]), _c('option', {
    attrs: {
      "value": "017"
    }
  }, [_vm._v("017")]), _c('option', {
    attrs: {
      "value": "019"
    }
  }, [_vm._v("019")])]), _c('span', {
    staticClass: "text"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone2,
      expression: "phone2"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.phone2
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.phone2 = $event.target.value;
      }, function ($event) {
        _vm.phone2 = _vm.phone2.replace(/[^0-9]/, '');
      }]
    }
  }), _c('span', {
    staticClass: "text"
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.phone3,
      expression: "phone3"
    }],
    staticClass: "input",
    attrs: {
      "type": "tel",
      "maxlength": "4"
    },
    domProps: {
      "value": _vm.phone3
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.phone3 = $event.target.value;
      }, function ($event) {
        _vm.phone3 = _vm.phone3.replace(/[^0-9]/, '');
      }]
    }
  })])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.accessToken === undefined,
      expression: "$route.query.accessToken === undefined"
    }],
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("비밀번호")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "input",
    attrs: {
      "type": "password",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.password = $event.target.value;
      }
    }
  }), _c('small', {
    staticClass: "text-grey text-grey--right"
  }, [_vm._v("(영문대소문자/숫자/특수문자 중 2가지 이상 조합, 8 - 16자)")])])]), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$route.query.accessToken === undefined,
      expression: "$route.query.accessToken === undefined"
    }],
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("비밀번호 확인")]), _c('div', {
    attrs: {
      "role": "cell"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password2,
      expression: "password2"
    }],
    staticClass: "input",
    attrs: {
      "type": "password",
      "name": "",
      "value": ""
    },
    domProps: {
      "value": _vm.password2
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.password2 = $event.target.value;
      }
    }
  })])])])])])]), _c('div', {
    staticClass: "bottom-button"
  }, [_c('a', {
    staticClass: "button button--border-primary",
    attrs: {
      "href": "",
      "onclick": "return false;"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_c('span', [_vm._v("취소하기")])]), _c('a', {
    staticClass: "button button--primary",
    on: {
      "click": _vm.join
    }
  }, [_c('span', [_vm._v("회원가입")])])])])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('nav', {
    staticClass: "join-step"
  }, [_c('ul', {
    staticClass: "join-step-lists"
  }, [_c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("1")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("약관동의")])]), _c('li', {
    staticClass: "list list--on"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("2")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("정보입력")])]), _c('li', {
    staticClass: "list"
  }, [_c('span', {
    staticClass: "badge"
  }, [_vm._v("3")]), _c('span', {
    staticClass: "text"
  }, [_vm._v("가입완료")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "join-caption join-caption--center"
  }, [_c('h3', {
    staticClass: "title"
  }, [_vm._v("회원정보 필수입력")])]);

}]

export { render, staticRenderFns }